// We use this file as an entrypoint for Webpack.

// By requiring context we specify that all files
// in [folder] should be required and bundled
// The require.context exports an API with functions to
// resolve the individual modules

// https://github.com/webpack/docs/wiki/context

function requireAll (r) {
  r.keys().forEach(r);
}

// The Script Loader plugin executes scripts once in global context
// This is great for legacy scripts
// https://github.com/webpack-contrib/script-loader

require('script-loader!./Global/Libraries/swiper/swiper-bundle.min.js');
require('script-loader!./Global/Libraries/jquery.bxslider/jquery.bxslider.js');
require('script-loader!./Global/Libraries/utils.js');
require('script-loader!./Global/Libraries/jRespond.js');
require('script-loader!./Global/Libraries/snap.js');
require('script-loader!./Global/Libraries/bootstrap-3.1.1/collapse.js');
require('script-loader!./Global/Libraries/bootstrap-3.1.1/transition.js');
require('script-loader!./Global/Libraries/bootstrap-3.1.1/tooltip.js');
require('script-loader!./Global/Libraries/bootstrap-3.1.1/popover.js');
require('script-loader!./Global/Libraries/bootstrap-3.1.1/tab.js');
require('script-loader!./Global/Libraries/bootstrap-3.1.1/datepicker.js');
require('script-loader!./Global/Libraries/bootstrap-3.1.1/locales/datepicker.da.js');
require('script-loader!./Global/Libraries/bootstrap-3.1.1/bootstrap-3.1.1.js');
require('script-loader!./Global/Libraries/bootstrap-3.1.1/multiselect.js');
require('script-loader!./Global/Libraries/typeahead.js/typeahead.bundle.js');
require('script-loader!./Global/Libraries/handlebars-v3.0.3.js');
require('script-loader!./Global/Libraries/jquery.viewportchecker.js');
require('script-loader!./Global/Libraries/jquery.cycle.all.js');
require('script-loader!./Global/Libraries/modernizr/custom-build.js');
//require('script-loader!./Global/Libraries/bodymovin/bodymovin.js');
//require('script-loader!./Global/Libraries/bodymovin/expandable-tab-spot');